/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import "../scss/site.scss"
import Scroll from "../components/Scroll"


const Layout = ({ children,location,changeTransitionSizeTo0,changeTransitionSizeTo10,pageTranScroll,pageTranScroll0 }) => {
  return <main className="app">
    <Scroll callbacks={location} changeTransitionSizeTo0={changeTransitionSizeTo0} changeTransitionSizeTo10 ={changeTransitionSizeTo10} pageTranScroll={pageTranScroll} pageTranScroll0={pageTranScroll0}/>
    {children}</main>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
