import LocomotiveScroll from "locomotive-scroll"
import { useEffect } from "react"
import gsap from "gsap"
import { navigate } from "@reach/router"

const Scroll = ({ callbacks, changeTransitionSizeTo0, changeTransitionSizeTo10, pageTranScroll, pageTranScroll0 }) => {
  useEffect(() => {
    const scrollOptions = {
      container: ".data-scroll-container",
      options: {
        smooth: true,
        multiplier: 1.15,
        getSpeed: true,
        lerp: 0.1,
        getDirection: true,
        tablet: {
          smooth: true,
        },
      },
    }

    if (typeof window === "undefined" || !window.document) {
      return
    }
    var locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scrollOptions.container),
      ...scrollOptions.options,
    })

    locomotiveScroll.update()

    window.scroll = locomotiveScroll

    document.querySelector("#menu-button").addEventListener("click", () => {
      locomotiveScroll.stop()
    })

    document.querySelector("#cross").addEventListener("click", () => {
      locomotiveScroll.start()
    })

    document.addEventListener("page-loaded", () => {
      locomotiveScroll.start()
    })
    function nav() {
      const link = document.querySelector("#discoverLink")
      if (link) {
        link.addEventListener("click", () => {
          if (window.location.pathname !== "/") {
            navigate("/").then(() => {
              const target = document.querySelector("#discoverHome")
              locomotiveScroll.scrollTo(target)
            })
          } else {
          }
        })
      }
    }
    nav()

    locomotiveScroll.on("call", (value, way, obj) => {
      if (value === "fade-in") {
        let tl = gsap.timeline()
        tl.add("one")

        tl.to(obj.target, { opacity: 1, ease: "power1.out", duration: 1, y: `0%` }, "one")
      }

      if (value === "scale") {
        let img = obj.target?.childNodes[0]?.childNodes[1]?.childNodes[1]
        let tl = gsap.timeline()
        tl.add("start")
        tl.to(img, { transform: "scale(1.05)", duration: 10, ease: "power1.out" }, "start")
      }

      if (value === "triggerPageTran" && way === "enter") {
        changeTransitionSizeTo10()
        pageTranScroll()
      } else if (value === "triggerPageTran" && way === "exit") {
        changeTransitionSizeTo0()
        pageTranScroll0()
      } else {
        const duration = 0.5
        const ease = "power1.out"
        // Using modularJS

        if (value === "invertNav" && way === "enter") {
          const first = "start"
          const baseColor = "#2a2a2a"
          const nav = document.querySelector("#nav-bar")
          nav.classList.remove("back-tran")

          let tl = gsap.timeline({ paused: true })
          tl.add("start")
          tl.to("#nav-bar", { background: "white", duration: duration, ease: ease }, first)
          tl.to("#nav-bar div", { color: baseColor, duration: duration, ease: ease }, first)
          tl.to("#nav-bar svg", { fill: baseColor, duration: duration, ease: ease }, first)
          tl.to("#nav-bar", { borderBottom: "1px solid black", duration: duration, ease: ease }, "start-=0.5")
          tl.to("#nav-bar div.button", { color: baseColor, duration: duration, ease: "power1.out" }, first)
          tl.to(
            "#middle-bar",
            {
              borderBottom: `1px solid black`,
              duration: duration,
              ease: "power1.out",
            },
            first
          )
          tl.to(
            "#top-bars",
            {
              borderBottom: `1px solid black`,
              borderTop: `1px solid black`,
              duration: duration,
              ease: "power1.out",
            },
            first
          )

          tl.play()
        } else if (value === "invertNavTran" && way === "enter") {
          if (obj) {
            const nav = document.querySelector("#nav-bar")

            const el = obj.target
            if (el) {
              const black = el.classList.contains("black")
              const first = "start"

              let color = "2a2a2a"
              let border = "black"

              if (!black) {
                color = "white"
              }

              let back = "white"
              if (color !== "2a2a2a") {
                back = "transparent"
                border = "white"
                nav.classList.add("back-tran")
              }
              let val = `rgba(0, 0, 0, 1)`
              if (color !== "2a2a2a") {
                val = `white`
              }

              let tl = gsap.timeline({ paused: true })
              tl.add(first)
              tl.to("#nav-bar", { backgroundColor: `${back}`, duration: duration, ease: ease }, first)
              tl.to("#nav-bar div", { color: `${color}`, duration: duration, ease: ease }, first)
              tl.to("#nav-bar svg", { fill: `${color}`, duration: duration, ease: ease }, first)
              tl.to(
                "#nav-bar",
                {
                  borderBottom: `1px solid ${border}`,
                  duration: duration,
                  ease: ease,
                },
                "start-=0.5"
              )
              tl.to("#nav-bar div.button", { color: `${color}`, duration: duration, ease: ease }, first)
              tl.to(
                "#middle-bar",
                {
                  borderBottom: `1px solid ${border}`,
                  duration: duration,
                  ease: ease,
                },
                first
              )
              tl.to(
                "#top-bars",
                {
                  borderBottom: `1px solid ${val}`,
                  borderTop: `1px solid ${val}`,
                  duration: duration,
                  ease: ease,
                },
                first
              )

              tl.play()
            }
          }
        }
      }
    })
    if (window.location.pathname === "/") {
      locomotiveScroll.stop()
    }

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [callbacks, pageTranScroll, pageTranScroll0, changeTransitionSizeTo0, changeTransitionSizeTo10])

  return null
}
export default Scroll
